import React from "react";
import SEO from "../../components/seo";
import Blog from "../../components/Blog";
import Par from "../../components/Par";
import H1 from "../../components/h1";

const title = "Iron Deficiency";

// Download link - http://www.fbrennangastro.com.au/downloads/gesairondeficiency.pdf

export default () => (
	<Blog title={title}>
		<SEO
			title={title}
			keywords={[title, "haemoglobin", "red blood cells", "anaemia"]}
			type="article"
			description="Iron deficiency, the most common nutritional deficiency in Australia. How to recognise it, understand it and treat it."
		/>
		<H1>{title}</H1>
		<Par>
			Iron deficiency is still the most common nutritional deficiency in Australia, especially among children and women
			of childbearing age where it can lead to iron-deficiency anaemia, a serious condition where red blood cell
			production falls.
		</Par>
		<Par>If dietary iron requirements are not met, the body's iron stores gradually run down.</Par>
		<Par>
			Iron is an important dietary mineral involved in various bodily functions. Iron is part of haemoglobin, the red
			pigment in the blood that carries oxygen from the lungs throughout the body, and myoglobin that transports oxygen
			in the muscles. Iron deficiency means less oxygen is delivered to the cells leading to fatigue, tiredness and
			decreased immunity.
		</Par>
		<Par>
			High-risk groups include menstruating women, pregnant women, babies and toddlers, teenage girls and female
			athletes. Indigenous Australians, refugees and recent immigrants from developing countries, vegans and
			vegetarians, people who repeatedly crash diet, and hospitalised or institutionalised patients are also at risk.
		</Par>
	</Blog>
);
